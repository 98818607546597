import { Slide } from "@mui/material";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, sendEmailVerification, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { forwardRef } from "react";
import { auth, firestore, functions } from "../firebase.config";
import { AES, enc } from "crypto-js";

export const getNumberOfWords = (stringToCount) => {
const words = stringToCount.split(' ');
let count = 0;
    for (let i = 0; i < words.length; i++) {
        if (words[i] !== '') {
            count++;
        }
    }
    return count;
};

export const getFullAddressString = (addressObject) => {
    return `${addressObject.street} ${addressObject.number}${addressObject.stair !== "" ? "/" + addressObject.stair : ""}${addressObject.flat !== "" ? "/" + addressObject.flat : ""}, ${addressObject.zip} ${addressObject.city}`;
};

// checks and returns if a passed-in date lies in the future, past or if it is the present day
export const checkDateLocation = (dateToCheck) => {
    const thisDate = new Date();
    const thisDay = thisDate.getDate();
    const thisMonth = thisDate.getMonth();
    const thisYear = thisDate.getFullYear();

    const checkDate = new Date(dateToCheck);
    const checkDay = checkDate.getDate();
    const checkMonth = checkDate.getMonth();
    const checkYear = checkDate.getFullYear();

    if (thisDate - checkDate < 0) {
        return "future";
    } else {
        if(thisDay === checkDay && thisMonth === checkMonth && thisYear === checkYear) {
            return "today";
        } else {
            return "past";
        }
    }
};

export const todayPlusYears = (numberOfYears) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + numberOfYears);
    const lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + lastday;
};

// returns if passed in string is a valid date
export const isValidDate = (dateString) => {
    return !isNaN(Date.parse(dateString));
};

export const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const handleGoogleSignIn = async (code) => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({affiliateCode: code});
    await signInWithPopup(auth, provider)
    .catch((err) => {
        console.log(err)
    })
};

export const handleMicrosoftSignIn = async (code) => {
    const provider = new OAuthProvider('microsoft.com');
    //provider.setCustomParameters({affiliateCode: code});
    await signInWithRedirect(auth, provider)
    .then((user) => {
        //console.log("EMAIL VERIFIED: ", user.user.emailVerified)
    })
    .catch((err) => {
        console.log(err)
    })
};

export const handleFacebookSignIn = async () => {
    await signInWithPopup(auth, new FacebookAuthProvider())
    .then((user) => {
        if(!user.user.emailVerified) {
            sendEmailVerification(auth.currentUser);
        }
    })
    .catch((err) => {
        console.log(err)
    })
};

export const convertLongTimestamp = (unixTime) => {
    const date = new Date(unixTime);
    return date.toLocaleString("de-DE", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })
};

export const convertShortTimestamp = (unixTime) => {
    const date = new Date(unixTime);
    return date.toLocaleString("de-DE", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
};

export const convertMonthYearTimestamp = (unixTime) => {
    const date = new Date(unixTime);
    return date.toLocaleString("de-DE", {
        year: 'numeric',
        month: 'long',
    })
};

export const hideString = (stringToSlice, amount) => {
    return stringToSlice.slice(0, amount) + "********" + stringToSlice.slice(stringToSlice.length - amount, stringToSlice.length)
};

export const getSearchRating = (search) => {
    let rating = 0;
    let params = 0;

    if(search?.deal === "rent") {
        if(search?.pets) {
            rating++;
        }
        params++;

        if(search?.shortTermRent) {
            rating++;
        }
        params++;
    }

    if(search?.type !== "land") {
        if(search?.airCondition) {
            rating++;
        }
        params++;

        if(search?.floorHeating !== "-") {
            rating++;
        }
        params++;

        if(search?.furnished !== "-") {
            rating++;
        }
        params++;

        if(search?.avoidCompensation) {
            rating++;
        }
        params++;
    
        if(search?.kitchen) {
            rating++;
        }
        params++;
    
        if(search?.cellar) {
            rating++;
        }
        params++;
    
        if(search?.elevator) {
            rating++;
        }
        params++;
    
        if(search?.bathtub) {
            rating++;
        }
        params++;
    
        if(search?.garage) {
            rating++;
        }
        params++;
    }
    
    if(search?.type === "land") {
        if(search?.developed) {
            rating++;
        }
        params++;

        if(search?.noOldGrowth) {
            rating++;
        }
        params++;

        if(!search?.first && !search?.new && !search?.renovation && !search?.project) {
            if(search?.new) {
                rating++;
            }

            if(search?.first) {
                rating++;
            }

            if(search?.project) {
                rating++;
            }

            if(search?.renovation) {
                rating++;
            }
            params++;
        }
    }

    if(["apartment", "commercial"].includes(search?.type)) {
        if(search?.storageRoom) {
            rating++;
        }
        params++;

        if(search?.attic !== "-") {
            rating++;
        }
        params++;

        if(search?.blinds) {
            rating++;
        }
        params++;

        if(search?.groundFloor !== "-") {
            rating++;
        }
        params++;

        if(search?.building !== "-") {
            rating++;
        }
        params++;

        if(search?.areasMin !== 0) {
            rating++;
        }
        params++;

        if(search?.balconyMin !== 0) {
            rating++;
        }
        params++;

        if(search?.gardenMin !== 0) {
            rating++;
        }
        params++;

        if(search?.rooftopMin !== 0) {
            rating++;
        }
        params++;
    }

    if(search?.type === "house") {
        if(search?.pool) {
            rating++;
        }
        params++;
    }
    

    return rating/params;
};

// Encryption

export const encryptString = (plainText) => {
    return AES.encrypt(plainText, "securestkeyofalltime").toString();
};

export const decryptString = (cipherText) => {
    const bytes = AES.decrypt(cipherText, "securestkeyofalltime");
    return bytes.toString(enc.Utf8);
};

// Database functions

export const addFirestoreColl = async (collName, dataToWrite) => {
    await addDoc(collection(firestore, collName), dataToWrite);
};

export const setFirestoreColl = async (collName, docId, dataToWrite) => {
    await setDoc(doc(firestore, collName, docId), dataToWrite);
};

export const updateFirestoreDoc = async (collName, docID, dataToWrite) => {
    await updateDoc(doc(firestore, collName, docID), dataToWrite);
};

export const DeleteSearch = (data) => {
    updateFirestoreDoc("searches", data.id, {deleted: true});
};

// Server functions

export const contactMatched = httpsCallable(functions, 'contactMatched');

export const acceptOffer = httpsCallable(functions, 'acceptOffer');

export const objectMatchingAnalysis = httpsCallable(functions, 'objectMatchingAnalysis');

export const deleteSingleObject = httpsCallable(functions, 'deleteSingleObject');

export const deleteSingleSearch = httpsCallable(functions, 'deleteSingleSearch');

export const deleteChat = httpsCallable(functions, 'deleteChat');

export const searchCreation = httpsCallable(functions, 'searchCreation');

export const objectCreation = httpsCallable(functions, 'objectCreation');

export const buySearches = httpsCallable(functions, 'buySearches');

export const sendMessage = httpsCallable(functions, "sendMessage");

export const sendEmailNotification = httpsCallable(functions, "sendEmailNotification");

export const createAffiliate = httpsCallable(functions, "createAffiliate");

export const updateProfile = httpsCallable(functions, "updateProfile");

export const createStripePaymentLink = httpsCallable(functions, "createStripePaymentLink");

export const getAffiliatePayments = httpsCallable(functions, "sendAffiliatePayments");

export const getCustomerObjectsShortInfo = httpsCallable(functions, "sendCustomerObjectsShortInfo");

export const getCustomerEmails = httpsCallable(functions, "sendCustomerEmailsAdmin");

export const addCredits = httpsCallable(functions, "addCreditsAdmin");

export const sendUpdateEmail = httpsCallable(functions, "sendUpdateEmail");

export const deleteUserData = httpsCallable(functions, "deleteUser");

export const updateObject = httpsCallable(functions, "updateObject");

export const activateObject = httpsCallable(functions, "activateObject");

export const deactivateObject = httpsCallable(functions, "deactivateObject");

export const deactivateSearch = httpsCallable(functions, "deactivateSearch");

// ADMIN functions

export const objectDeleteCompleteAdmin = httpsCallable(functions, "objectDeleteCompleteAdmin");

export const userCreateAdmin = httpsCallable(functions, "userCreateAdmin");

export const deleteAllInterfaceObjects = httpsCallable(functions, "deleteAllInterfaceObjects");

// TEST functions

export const getSearchesWithoutPolygon = httpsCallable(functions, "getSearchesWithoutPolygon");

export const testObjectMatching = httpsCallable(functions, "singleObjectMatching");

export const testMatching = httpsCallable(functions, "testMatching");

export const ftpConnectionTest = httpsCallable(functions, "ftpInterface");

export const getUserFromEmail = httpsCallable(functions, "getUserFromEmail");

export const contactCleaning = httpsCallable(functions, "contactCleaning");

export const searchCleaning = httpsCallable(functions, "searchCleaning");

export const globalMatching = httpsCallable(functions, "globalMatching");

export const testFunction = httpsCallable(functions, "testFunction");

export const checkSearches = httpsCallable(functions, "checkSearches");

export const accountObjectMatching = httpsCallable(functions, "accountObjectMatching");

import { t } from "i18next";
import { Copyright, Dot } from "../helper/icons";
import { useStateContext } from "../contexts/ContextProvider";
import Flags from "./Flags";

const CopyrightInfo = () => {

  const { handleMenuState } = useStateContext();

  const getYear = () => {
    const today = new Date();
    return today.getFullYear();
  };

  return (
    <div className="flex fixed bottom-2 flex-col items-center justify-center w-full text-allimmoDark/40">
      <div className="flex flex-row items-center justify-center w-full">
        <Copyright size={15} />
        <p className="italic text-sm text-center mr-2">{getYear()}</p>
        <p className="italic text-sm text-center">v0.9.45</p>
      </div>

      <div className="flex flex-row items-center justify-center w-full gap-2 text-allimmoDark/40">
          <p onTouchStart={() => handleMenuState("agb")} onClick={() => handleMenuState("agb")} className="cursor-pointer hover:underline text-sm">{t('Menu.AGB')}</p>
          <Dot size={8}/>
          <p onTouchStart={() => handleMenuState("privacy")} onClick={() => handleMenuState("privacy")} className="cursor-pointer hover:underline text-sm">{t('Menu.Datenschutz')}</p>
          <Dot size={8}/>
          <p onTouchStart={() => handleMenuState("impressum")} onClick={() => handleMenuState("impressum")} className="cursor-pointer hover:underline text-sm">{t('Menu.Impressum')}</p>
      </div>
      <Flags />
    </div>
  );
};

export default CopyrightInfo
